import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { BoxCentered } from '../../BoxCentered/BoxCentered';
import { filterSelectDepartureDateOptions } from './options';
import { IFilters } from '../../../utils/types';

interface IFilterSelectDepartureDate {
  value?: string | number;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectDepartureDate = ({
  value,
  setFilter,
}: IFilterSelectDepartureDate) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl className={classes.filtersItemSelect}>
      <BoxCentered pl={2}>
        <Select
          displayEmpty
          disableUnderline
          value={value}
          onChange={(e) => {
            setFilter((state) => ({
              ...state,
              dateFilter: Number(e.target.value),
            }));
          }}
        >
          {filterSelectDepartureDateOptions.map((option) => (
            <MenuItem
              value={option.value}
              disabled={!option.value && option.value !== 0}
            >
              {option.nDays
                ? t(option.title, { value: option.nDays })
                : t(option.title)}
            </MenuItem>
          ))}
        </Select>
      </BoxCentered>
    </FormControl>
  );
};

export default FilterSelectDepartureDate;
