import React from 'react';

import { LIMIT_ITEM_PAGE, TRoutes } from '../../utils/helpers';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import {
  DATE_TIME_FORMAT,
  HISTORY_OF_MOVEMENT_TABLE_CELLS_ARRAY,
  MOVEMENT_OPERATIONS,
} from '../../utils/constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '../PaginationComponent/PaginationComponent';
import {
  WarehouseProductLog,
  WarehouseProductLogOperation,
} from '../../generated/graphql';
import TableBodyRequestHandler from '../TableBodyRequestHandler/TableRequestHandler';

const HistoryOfMovementProductsTable: React.FC<{
  history: WarehouseProductLog[];
  count?: number;
  page: number;
  setFilter: React.Dispatch<React.SetStateAction<{ page: number }>>;
  loading: boolean;
}> = ({ page, setFilter, history, count, loading }) => {
  const { t } = useTranslation();

  const getDateTime = (history: WarehouseProductLog) => {
    if (history.createdAt) {
      return moment(Number(history.createdAt)).format(DATE_TIME_FORMAT);
    }
    return t('app.unknown');
  };

  const getMovementOperationText = (history: WarehouseProductLog) => {
    if (history.operation) {
      const operation = t(MOVEMENT_OPERATIONS[history.operation]);
      if (operation) return operation;
    }

    return t('app.unknownOperation');
  };

  const getDetails = (history: WarehouseProductLog) => {
    switch (history.operation) {
      case WarehouseProductLogOperation.Inbound:
        return (
          <Link
            to={`${
              TRoutes.WAREHOUSE_SHIPMENT_DETAILS_WITHOUT_ID
            }${history.shipmentId?.toString()}`}
          >
            {history.comment}
          </Link>
        );
      case WarehouseProductLogOperation.Sending:
      case WarehouseProductLogOperation.OrderPicking:
      case WarehouseProductLogOperation.OrderCancel:
        return (
          <Link
            to={`${
              TRoutes.SHIPMENT_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
            }${history.shipmentId?.toString()}`}
          >
            {history.comment}
          </Link>
        );
      case WarehouseProductLogOperation.Return:
        return (
          <Link
            to={`${
              TRoutes.RETURN_DETAILS_WITHOUT_ID
            }${history.shipmentId?.toString()}`}
          >
            {history.comment}
          </Link>
        );
      case WarehouseProductLogOperation.UndeclaredInbound:
        return (
          <Link
            to={`${
              TRoutes.UNDECLARED_ARRIVAL_DETAILS_WITHOUT_ID
            }${history.shipmentId?.toString()}`}
          >
            {history.comment}
          </Link>
        );

      default:
        return history.comment;
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {HISTORY_OF_MOVEMENT_TABLE_CELLS_ARRAY.map((text) => (
                <TableCell align='center' rowSpan={2}>
                  {t(text)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableBodyRequestHandler
              dataLength={2}
              colSpan={HISTORY_OF_MOVEMENT_TABLE_CELLS_ARRAY.length}
              loading={loading}
            >
              {history?.map((history: WarehouseProductLog) => (
                <TableRow key={history.id}>
                  <TableCell align='center'>{getDateTime(history)}</TableCell>
                  <TableCell align='center'>
                    {getMovementOperationText(history)}
                  </TableCell>
                  <TableCell align='center'>{getDetails(history)}</TableCell>
                  <TableCell align='center'>{history.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={count}
        limit={LIMIT_ITEM_PAGE}
        currentPage={page}
        setFilter={setFilter}
      />
    </>
  );
};

export default HistoryOfMovementProductsTable;
