import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import FilterSelectLimitItems from '../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import {
  useCancelWarehouseShipmentOrderMutation,
  useGetWarehouseShipmentOrdersLazyQuery,
  Warehouse_Shipment_Order_Type,
  WarehouseShipmentOrder,
} from '../../generated/graphql';
import {
  LOCAL_STORAGE_KEYS,
  SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY_WITHOUT_LABEL,
  SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY_WITH_LABEL,
} from '../../utils/constants';
import { DEPARTURE_DATE_FILTER, LIMIT_ITEM_PAGE } from '../../utils/helpers';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../utils/types';
import TableBodyRequestHandler from '../../components/TableBodyRequestHandler/TableRequestHandler';
import { QUERY_WAREHOUSE_SHIPMENT_ORDERS } from '../../GraphQL/queries/getWarehouseShipmentOrders';
import ShipmentFromWarehouseTableRowComponent from '../../components/ShipmetsFromWarehouseComponents/ShipmentFromWarehouseTableRowComponent/ShipmentFromWarehouseTableRowComponent';
import PaginationComponent from '../../components/PaginationComponent/PaginationComponent';
import CancelWarehouseShipmentOrderModal from '../../components/Modals/CancelWarehouseShipmentOrderModal';
import { IShipmentFromWarehouse } from './types';
import FilterSelectWarehouse from '../../components/_Fields/FilterSelectWarehouse/FilterSelectWarehouse';
import FilterSelectPaymentStatus from '../../components/_Fields/FilterSelectPaymentStatus/FilterSelectPaymentStatus';
import FilterSelectDepartureDate from '../../components/_Fields/FilterSelectDepartureDate/FilterSelectDepartureDate';
import FilterSelectDeliveryStatus from '../../components/_Fields/FilterSelectDeliveryStatus/FilterSelectDeliveryStatus';

const ShipmentFromWarehouse = ({
  filterTabs,
  type,
}: IShipmentFromWarehouse) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isWithLabel = type === Warehouse_Shipment_Order_Type.WithBuyLabel;

  const itemsLimit = localStorage.getItem(
    LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
  );
  const warehouseId = localStorage.getItem(
    LOCAL_STORAGE_KEYS.WAREHOUSE_ID_ORDER,
  );

  const [shipmentOrders, setShipmentOrders] = useState<
    WarehouseShipmentOrder[] | null
  >(null);
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState(false);
  const [orderToCancel, setOrderToCancel] =
    useState<WarehouseShipmentOrder | null>(null);
  const [filter, setFilter] = useState<IFilters>({
    paymentStatusId: '',
    dateFilter: 0,
    search: filterTabs?.search,
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    warehouseId: warehouseId ? warehouseId : '2',
  });

  const [
    cancelWarehouseShipmentOrderMutation,
    { loading: cancelWarehouseShipmentOrderLoading },
  ] = useCancelWarehouseShipmentOrderMutation();

  const [getWarehouseShipmentOrdersLazyQuery, { data, loading }] =
    useGetWarehouseShipmentOrdersLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  useEffect(() => {
    if (!data?.getWarehouseShipmentOrders?.rows) return;

    data?.getWarehouseShipmentOrders?.rows &&
      setShipmentOrders(
        data?.getWarehouseShipmentOrders?.rows as WarehouseShipmentOrder[],
      );
  }, [data]);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, search: filterTabs?.search }));
    // eslint-disable-next-line
  }, [filterTabs]);

  useEffect(() => {
    getWarehouseShipmentOrdersLazyQuery({
      variables: {
        data: {
          offset: (filter.page - 1) * filter.itemsLimit!,
          limit: filter.itemsLimit,
          search: filter.search,
          dateFilter:
            typeof filter.dateFilter === 'string'
              ? DEPARTURE_DATE_FILTER.ALL_TIME
              : filter.dateFilter,

          ...(filter.paymentStatusId && {
            paymentStatusId: filter.paymentStatusId,
          }),
          ...(filter.deliveryStatusId && {
            deliveryStatusId: filter.deliveryStatusId.toString(),
          }),
          warehouseId: filter.warehouseId,
          type,
        },
      },
    });
    // eslint-disable-next-line
  }, [filter]);

  const cancelWarehouseShipmentOrderHandler = (id: string) => {
    cancelWarehouseShipmentOrderMutation({
      variables: { id },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.orderDeleted'), { variant: 'success' });
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [QUERY_WAREHOUSE_SHIPMENT_ORDERS],
    });
  };

  const tableCells = isWithLabel
    ? SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY_WITH_LABEL
    : SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY_WITHOUT_LABEL;

  return (
    <>
      <div className={classes.filters}>
        <FilterSelectWarehouse
          loading={loading}
          value={filter.warehouseId as string}
          setFilter={setFilter}
        />
        <FilterSelectDepartureDate
          value={filter.dateFilter}
          setFilter={setFilter}
        />
        <FilterSelectDeliveryStatus
          value={filter.deliveryStatusId}
          setFilter={setFilter}
        />
        <FilterSelectPaymentStatus
          value={filter.paymentStatusId}
          setFilter={setFilter}
        />
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />
      </div>
      <TableContainer component={Paper}>
        <Table arial-label='таблица отправлении'>
          <TableHead>
            {tableCells.map((cell) => (
              <TableCell align='center'>{t(cell)}</TableCell>
            ))}
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={shipmentOrders?.length as number}
              colSpan={9}
              loadingText={t('app.shipments').toLowerCase()}
              loading={loading}
            >
              {shipmentOrders
                ? shipmentOrders?.map((order) => {
                    return (
                      <ShipmentFromWarehouseTableRowComponent
                        key={order.id}
                        order={order}
                        setOrderToCancel={setOrderToCancel}
                        setIsOpenConfirmCancel={setIsOpenConfirmCancel}
                        isWithLabel={isWithLabel}
                      />
                    );
                  })
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getWarehouseShipmentOrders?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />

      <CancelWarehouseShipmentOrderModal
        orderId={orderToCancel?.id as string}
        isOpenConfirmCancel={isOpenConfirmCancel}
        setIsOpenConfirmCancel={setIsOpenConfirmCancel}
        setOrderToCancel={setOrderToCancel}
        cancelWarehouseShipmentOrderHandler={
          cancelWarehouseShipmentOrderHandler
        }
        cancelWarehouseShipmentOrderLoading={
          cancelWarehouseShipmentOrderLoading
        }
      />
    </>
  );
};

export default ShipmentFromWarehouse;
