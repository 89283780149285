import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  warehouseStatuses,
} from '../../../utils/constants';
import styles from './styles';
import { Link, useParams } from 'react-router-dom';
import {
  ReturnShipment,
  ReturnShipmentItem,
  useGetReturnShipmentInfoQuery,
  WarehouseShipmentStatus,
} from '../../../generated/graphql';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import TrackTrackNumberComponent from '../../../components/TrackTrackNumberComponent/TrackTrackNumberComponent';
import TableList from '../../../components/TableList/TableList';
import { headCells } from './options';
import { TRoutes } from '../../../utils/helpers';

const createData = (tableRowData: ReturnShipmentItem) => {
  const titleId = tableRowData?.productBarcode?.split('.')[0];
  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: tableRowData?.productSku,
    productName: tableRowData?.productName,
    quantity: tableRowData?.acceptedQuantity,
  };
};

const ReturnShipmentDetails: React.FC = () => {
  const { t } = useTranslation();
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [returnShipmentInfo, setReturnShipmentInfo] =
    useState<ReturnShipment | null>(null);

  const { data, loading } = useGetReturnShipmentInfoQuery({
    variables: {
      id: +id,
    },
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useEffect(() => {
    if (data) {
      setReturnShipmentInfo(data?.getReturnShipmentInfo as ReturnShipment);
    }
  }, [data]);

  const rows =
    returnShipmentInfo?.products?.map((tableRowData) =>
      createData(tableRowData as ReturnShipmentItem),
    ) || [];

  const formattedDate = moment(Number(returnShipmentInfo?.createdAt)).format(
    DATE_FORMAT,
  );

  const formattedArrivalDate = moment(
    Number(returnShipmentInfo?.arrivalDate),
  ).format(DATE_TIME_FORMAT);

  const shipmentIdTitle =
    returnShipmentInfo?.shipmentOrderBarcodeId?.split('.')[0];

  return (
    <RequestHandler loading={loading}>
      <>
        <Box mb={4}>
          <Typography className={classes.title} variant='h2' align='center'>
            {t('app.viewReturn')}
          </Typography>
        </Box>
        <Grid container spacing={0} alignItems='stretch'>
          <Grid item sm={6} xs={12}>
            <List>
              <ListItem>
                <Typography variant='h6'>{`${t('app.ID')}: ${
                  returnShipmentInfo?.barcodeId?.split('.')[0]
                }`}</Typography>
              </ListItem>
              <ListItem className={classes.itemInfo}>
                {`${t('app.shipmentDate')}: ${formattedDate}`}
              </ListItem>
              <ListItem className={classes.itemInfo}>
                {`${t('app.returnType')}: ${returnShipmentInfo?.type}`}
              </ListItem>
              <ListItem className={classes.itemInfo}>
                {`${t('app.warehouseStatus')}: ${t(
                  warehouseStatuses[
                    data?.getReturnShipmentInfo
                      ?.warehouseStatus as WarehouseShipmentStatus
                  ],
                )} ${formattedArrivalDate} ${t('app.warehouse')} ${
                  returnShipmentInfo?.warehouse
                }`}
              </ListItem>
              <Divider />
              <ListItem className={classes.itemInfo}>
                {`${t('app.shipmentNo2')}:  `}
                <Link
                  to={`${
                    TRoutes.SHIPMENT_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
                  }${returnShipmentInfo?.shipmentOrderId?.toString()}`}
                >
                  {shipmentIdTitle}
                </Link>
              </ListItem>
              {returnShipmentInfo?.trackNumber &&
                returnShipmentInfo?.carrierCode && (
                  <ListItem className={classes.itemInfo}>
                    {`${t('app.trackingNumber')}: `}
                    <TrackTrackNumberComponent
                      carrierCode={returnShipmentInfo?.carrierCode}
                      trackNumber={returnShipmentInfo?.trackNumber}
                    />
                  </ListItem>
                )}
              <Divider />
              <ListItem className={classes.itemInfo}>
                {`${t('app.warehouse')} ${returnShipmentInfo?.warehouse}`}
              </ListItem>
              <ListItem className={classes.itemInfo}>
                {`${t('app.processingCost')} ${returnShipmentInfo?.cost} USD`}
              </ListItem>
            </List>
          </Grid>
        </Grid>
        {rows.length ? <TableList headCells={headCells} rows={rows} /> : null}

        {returnShipmentInfo?.attachments?.map((item) => (
          <Link
            key={item?.filePath}
            to={`/api/getAttachment/${item?.filePath}`}
            target='_blank'
          >
            <img
              className={classes.image}
              src={`${process.env.REACT_APP_API_DOMAIN}/api/getAttachment/${item?.filePath}`}
              alt={item?.filePath as string}
              loading='lazy'
            />
          </Link>
        ))}
      </>
    </RequestHandler>
  );
};

export default ReturnShipmentDetails;
