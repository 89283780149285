import { FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from '../styles';
import { IFilters } from '../../../utils/types';
import { LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import {
  Warehouse,
  useGetWarehousesListQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';

const FilterSelectWarehouse: React.FC<{
  loading: boolean;
  value: string;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}> = ({ loading, value, setFilter }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [warehouseList, setWarehouseList] = useState<Warehouse[] | null>(null);

  const { data: warehousesData } = useGetWarehousesListQuery({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useEffect(() => {
    if (warehousesData) {
      setWarehouseList(warehousesData?.getWarehousesList?.rows as Warehouse[]);
    }
  }, [warehousesData]);

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        disabled={loading}
        value={value}
        onChange={(e) => {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.WAREHOUSE_ID_ORDER,
            e.target.value as string,
          );
          setFilter((state) => ({
            ...state,
            warehouseId: e.target.value as string,
          }));
        }}
      >
        {warehouseList?.map((item: any) => (
          <MenuItem key={item.code} value={item.id}>
            {item.code}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectWarehouse;
