import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import EditIcon from '@material-ui/icons/Edit';
import {
  COLORS,
  DATE_FORMAT,
  LOCAL_STORAGE_KEYS,
} from '../../../utils/constants';
import CancelIcon from '@material-ui/icons/Cancel';
import ModalBox from '../../../components/ModalBox/ModalBox';
import MyProductForm from '../../../components/MyProductForm/MyProductForm';
import {
  Product,
  useGetMyProductListLazyQuery,
  useGetWarehousesListQuery,
  useImportProductListFromFileMutation,
  useRemoveProductMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../../utils/helpers';
import { useDebouncedCallback } from 'use-debounce/lib';
import FilterSelectLimitItems from '../../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import AddIcon from '@material-ui/icons/Add';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FilterInputSearch from '../../../components/_Fields/FilterInputSearch/FilterInputSearch';
import { Link } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { isObject } from 'formik';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';

interface IMyProductsFilter {
  page: number;
  itemsLimit: number;
  warehouseId: string;
  search: string;
}

const itemsLimit = localStorage.getItem(
  LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
);

const warehouseId = localStorage.getItem(LOCAL_STORAGE_KEYS.WAREHOUSE_ID);

const MyProductList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const classes = useStyles();
  const [isOpenUnitForm, setIsOpenUnitForm] = useState(false);
  const [editingProduct, setEditingProduct] = useState<null | Product>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingAction, setLoadingAction] = useState(false);
  const [isOpenConfirmRemove, setOpenConfirmRemove] = useState(false);
  const [productToRemove, setProductToRemove] = useState<Product | null>(null);

  const [warehouseList, setWarehouseList] = useState<any>([]);

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    warehouseId: warehouseId ? warehouseId : '',
    search: '',
  });
  const { data: dataWarehouses, error: errorWarehouses } =
    useGetWarehousesListQuery();

  const [getMyProductListLazyQuery, { data: dataProducts, loading, error }] =
    useGetMyProductListLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [removeProductMutation] = useRemoveProductMutation();

  const [
    importProductListFromFile,
    { loading: importProductListFromFileLoading },
  ] = useImportProductListFromFileMutation();

  useEffect(() => {
    if (!dataProducts?.getMyProductList?.rows) return;

    dataProducts?.getMyProductList?.rows &&
      setProducts(dataProducts?.getMyProductList?.rows as Product[]);

    // eslint-disable-next-line
  }, [dataProducts]);

  useEffect(() => {
    dataWarehouses?.getWarehousesList?.rows &&
      setWarehouseList(dataWarehouses?.getWarehousesList?.rows);
  }, [dataWarehouses]);

  useEffect(() => {
    refetchMyProductList();
    // eslint-disable-next-line
  }, [filter]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });
  errorWarehouses?.message &&
    enqueueSnackbar(errorWarehouses.message, { variant: 'error' });

  const refetchMyProductList = useDebouncedCallback(() => {
    setLoadingAction(true);
    getMyProductListLazyQuery({
      variables: {
        limit: filter.itemsLimit,
        offset: (filter.page - 1) * filter.itemsLimit!,
        search: filter.search,
        ...(filter.warehouseId && { warehouseId: filter.warehouseId }),
      },
    });
    setLoadingAction(false);
  }, 400);

  const handleRemove = (id: number) => {
    if (id) {
      removeProductMutation({
        variables: {
          productId: id,
        },
      })
        .then((value) => {
          if (value?.data?.removeProduct) {
            refetchMyProductList();
            enqueueSnackbar(t('app.itemSuccessfullyDeleted'), {
              variant: 'success',
            });
          }
        })
        .catch((reason) => {
          enqueueSnackbar(reason.message, { variant: 'error' });
        });
    }
  };

  const selectFileUpload = () => {
    let fileInput = document.getElementById('inputUploadFile');
    if (!fileInput) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('id', 'inputUploadFile');
      fileInput.setAttribute(
        'accept',
        '.xls,.xlsx,.xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12',
      );
      fileInput.style.visibility = 'hidden';
      document.body.append(fileInput);
      //@ts-ignore
      fileInput.addEventListener('change', uploadProductListFile, false);
      //@ts-ignore
      fileInput.type = 'file';
    }
    fileInput.click();
  };

  const uploadProductListFile = ({
    target: {
      //@ts-ignore
      validity,
      //@ts-ignore
      files: [file],
    },
  }) => {
    let dataArray = new FormData();
    const inputUploadFile = document.getElementById('inputUploadFile');
    //@ts-ignore
    if (inputUploadFile?.files?.length) {
      //@ts-ignore
      dataArray.append('file', inputUploadFile.files[0]);

      validity.valid &&
        importProductListFromFile({
          variables: {
            file: file,
          },
        })
          .then((value) => {
            if (value?.data?.importProductListFromFile) {
              refetchMyProductList();

              if (value?.data?.importProductListFromFile?.messages?.length) {
                enqueueSnackbar(
                  <div>
                    Файл получен и обработан! Но
                    {value?.data?.importProductListFromFile?.messages.map(
                      (message) => (
                        <div>{message}</div>
                      ),
                    )}
                  </div>,
                  {
                    variant: 'success',
                    autoHideDuration: 3000,
                  },
                );
              } else {
                enqueueSnackbar(t('app.fileReceivedAndProcessed'), {
                  variant: 'success',
                  autoHideDuration: 3000,
                });
              }
            } else {
              enqueueSnackbar(t('app.serverResponseSuccessButError'), {
                variant: 'success',
              });
            }
          })
          .catch((reason) => {
            reason?.message &&
              enqueueSnackbar(reason?.message, { variant: 'error' });
          });
      inputUploadFile.remove();
    }
  };

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.productsWarehouse').toUpperCase()}
      </Typography>

      <div className={classes.filters}>
        <FormControl className={classes.filtersItemSelect}>
          <Select
            displayEmpty
            disableUnderline
            disabled={loading}
            value={filter.warehouseId}
            onChange={(e) => {
              localStorage.setItem(
                LOCAL_STORAGE_KEYS.WAREHOUSE_ID,
                e.target.value as string,
              );
              setFilter((state) => ({
                ...state,
                warehouseId: e.target.value as string,
              }));
            }}
          >
            <MenuItem value={''}>{t('app.allWarehouses')}</MenuItem>
            {warehouseList?.map((item: any) => (
              <MenuItem key={item.code} value={item.id}>
                {item.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />

        <div className={classes.buttonsForSelected}>
          <Button
            variant='contained'
            className={classes.buttonForSelected}
            onClick={() => {
              setEditingProduct(null);
              setIsOpenUnitForm(true);
            }}
          >
            <AddIcon /> {t('app.add')}
          </Button>
          <Button
            variant='contained'
            startIcon={
              importProductListFromFileLoading && <CircularProgress size={22} />
            }
            onClick={selectFileUpload}
            disabled={importProductListFromFileLoading}
            className={classes.buttonForSelected}
          >
            {t('app.import')}
          </Button>
        </div>
        <FilterInputSearch setFilter={setFilter} />
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>{t('app.created')}</TableCell>
              <TableCell align='center'>{t('app.name')}</TableCell>
              <TableCell align='center'>SKU</TableCell>
              <TableCell align='center'>{t('app.inTransit')}</TableCell>
              <TableCell align='center'>{t('app.available')}</TableCell>
              <TableCell align='center'>{t('app.reserved')}</TableCell>
              <TableCell align='center'>{t('app.price')}</TableCell>
              <TableCell align='center'>{t('app.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!dataProducts || loading || loadingAction) && (
              <TableRow>
                <TableCell colSpan={4} align='center'>
                  <ShowLoadingText name={t('app.product2')} />
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              !loadingAction &&
              products?.map((product) => {
                return (
                  product && (
                    <TableRow>
                      <TableCell align='center'>
                        <Link
                          to={TRoutes.SETTINGS_MY_PRODUCT_DETAILS.replace(
                            ':id',
                            product.id.toString(),
                          )}
                        >
                          {product.barcodeId?.substring(
                            0,
                            product.barcodeId?.length - 4,
                          )}
                        </Link>
                      </TableCell>
                      <TableCell align='center'>
                        {moment(Number(product?.createdAt)).format(DATE_FORMAT)}
                      </TableCell>
                      <TableCell align='center'>{product.name}</TableCell>
                      <TableCell align='center'>{product.sku}</TableCell>
                      <TableCell align='center'>
                        {product.productAvailabilityQuantity?.inTransitQuantity}
                      </TableCell>
                      <TableCell align='center'>
                        {product.productAvailabilityQuantity?.availableQuantity}
                      </TableCell>
                      <TableCell align='center'>
                        {product.productAvailabilityQuantity?.reservedQuantity}
                      </TableCell>
                      <TableCell align='center'>
                        {`${product.declaredValue} ${product?.currency}`}
                      </TableCell>
                      <TableCell align='center'>
                        <Select value={t('app.action')}>
                          <MenuItem
                            value={t('app.action')}
                            disabled
                            style={{ display: 'none' }}
                          >
                            {t('app.action')}
                          </MenuItem>
                          <MenuItem
                            disableGutters
                            divider
                            className={classes.actionMenuItem}
                          >
                            <Link
                              className={classes.actionLink}
                              to={TRoutes.SETTINGS_MY_PRODUCT_DETAILS.replace(
                                ':id',
                                product.id.toString(),
                              )}
                            >
                              <SearchIcon
                                className={`${classes.actionIcon} ${classes.actionIconWatch}`}
                              />
                              {t('app.view')}
                            </Link>
                          </MenuItem>
                          <MenuItem
                            disableGutters
                            divider
                            className={classes.actionMenuItem}
                          >
                            <Tooltip title={t('app.clickToOpenBarcode')}>
                              <a
                                href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${product?.barcodeId}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={classes.actionLink}
                              >
                                <img
                                  src={iconBarcode}
                                  alt='barcode'
                                  className={`${classes.actionIcon}`}
                                  style={{ width: 20, height: 20 }}
                                />
                                {t('app.barcode')}
                              </a>
                            </Tooltip>
                          </MenuItem>
                          <MenuItem
                            disableGutters
                            divider
                            className={classes.actionMenuItem}
                          >
                            <Tooltip
                              title={t('app.sendToPickAndPackWarehouse')}
                            >
                              <Link
                                className={classes.actionLink}
                                to={
                                  TRoutes.SHIPMENT_FROM_WAREHOUSE_SELECT_TYPE_CREATE
                                }
                              >
                                <LocalShippingIcon
                                  className={`${classes.actionIcon}`}
                                  style={{ color: COLORS.BLUE }}
                                />
                                {t('app.sendFromWarehouse')}
                              </Link>
                            </Tooltip>
                          </MenuItem>
                          <MenuItem
                            disableGutters
                            divider
                            className={classes.actionMenuItem}
                          >
                            <Link
                              className={classes.actionLink}
                              to={`#`}
                              onClick={() => {
                                setEditingProduct(product);
                                setIsOpenUnitForm(true);
                              }}
                            >
                              <EditIcon
                                className={`${classes.actionIcon} ${classes.actionIconEdit}`}
                              />
                              {t('app.edit')}
                            </Link>
                          </MenuItem>
                          <MenuItem
                            disableGutters
                            divider
                            className={classes.actionMenuItem}
                            disabled={
                              !!product.productAvailabilityQuantity
                                ?.inTransitQuantity ||
                              !!product.productAvailabilityQuantity
                                ?.availableQuantity ||
                              !!product.productAvailabilityQuantity
                                ?.reservedQuantity
                            }
                          >
                            <Link
                              className={classes.actionLink}
                              to='#'
                              onClick={() => {
                                setOpenConfirmRemove(true);
                                setProductToRemove(product);
                              }}
                            >
                              <CancelIcon
                                className={`${classes.actionIcon} ${classes.actionIconCancel}`}
                              />
                              {t('app.delete')}
                            </Link>
                          </MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classes.pagination}
        count={
          dataProducts
            ? Math.ceil(
                //@ts-ignore
                dataProducts?.getMyProductList?.count / filter.itemsLimit,
              )
            : 1
        }
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />

      <ModalBox isOpen={isOpenUnitForm} setOpen={setIsOpenUnitForm}>
        <MyProductForm
          filter={filter}
          refetch={refetchMyProductList}
          editingProduct={editingProduct}
          setIsOpenUnitForm={setIsOpenUnitForm}
          setEditingProduct={setEditingProduct}
        />
      </ModalBox>
      <ModalBox
        isOpen={isOpenConfirmRemove && isObject(productToRemove)}
        setOpen={setOpenConfirmRemove}
      >
        <Typography variant='h6'>{t('app.confirmItemDeletion')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setOpenConfirmRemove(false);
              setProductToRemove(null);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>

          <Button
            variant='contained'
            onClick={() => {
              if (isObject(productToRemove)) {
                handleRemove(Number(productToRemove?.id));
              }
              setOpenConfirmRemove(false);
            }}
            disabled={loadingAction}
          >
            {t('app.delete')}
          </Button>
        </div>
      </ModalBox>
    </Container>
  );
};

export default MyProductList;
