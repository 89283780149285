import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

const drawerWidth = 266;
const drawerWidthHidden = 100;

export default (isSidebarHidden: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      logoLink: {
        display: 'flex',
        textDecoration: 'none',
      },
      drawer: {
        transition: 'flex 0.3s ease-out',
        [theme.breakpoints.up('md')]: {
          width: isSidebarHidden ? drawerWidthHidden : drawerWidth,
          flexShrink: 0,
        },
      },
      drawerPaper: {
        width: isSidebarHidden ? drawerWidthHidden : drawerWidth,
        backgroundColor: '#292D30',
        [theme.breakpoints.down('xs')]: {
          width: 320,
        },
      },
      appBar: {
        backgroundColor: 'transparent',

        [theme.breakpoints.up('md')]: {
          width: `calc(100% - ${
            isSidebarHidden ? drawerWidthHidden : drawerWidth
          }px)`,
          marginLeft: isSidebarHidden ? drawerWidthHidden : drawerWidth,
        },
        boxShadow: 'none',
      },
      toggleMenu: {
        padding: '0',
        margin: '0',
      },
      toolbar: {
        minHeight: 'auto',
        padding: '36px 4px',
      },
      balance: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        paddingRight: 16,
        color: '#606060',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '1.3px',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          '& $balanceIcon': {
            color: '#606060',
          },
        },
      },
      balanceIcon: {
        color: COLORS.BLACK,
      },
      person: {
        display: 'flex',
        alignItems: 'center',
        color: '#606060',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '1.3px',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          '& $personIcon': {
            color: '#606060',
          },
        },
      },
      personIcon: {
        color: COLORS.BLACK,
      },
      signOut: {
        color: COLORS.BLACK,
        lineHeight: '20px',
        padding: 0,
        textTransform: 'none',
        fontWeight: 'normal',
        '&:hover': {
          color: '#606060',
        },
      },
      logo: {
        marginTop: '32px',
        marginLeft: '24px',
        fontSize: '32px',
        lineHeight: '44px',
        fontWeight: 700,
        fontFamily: '"Montserrat", sans-serif',
        color: COLORS.WHITE,
        letterSpacing: '1px',
      },
      logoInner: {
        color: '#a3b4d2',
      },
      logoDescriprion: { fontSize: '24px' },
      logoWithHiddenSidebar: {
        maxHeight: 45,
        margin: '28px auto auto',
      },
      content: {
        flexGrow: 1,
        minHeight: '100vh',
        width: `calc(100% - ${
          isSidebarHidden ? drawerWidthHidden : drawerWidth
        }px)`,
        padding: 20,
        paddingLeft: 32,
        paddingRight: 16,

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
      container: {
        maxWidth: '100%',
        padding: 0,
        height: '100%',
      },
      sidebarToggler: {
        cursor: 'pointer',
        color: COLORS.WHITE,
        display: 'flex',
        justifyContent: 'center',
        padding: '15px 0',
      },
    }),
  )();
