import React from 'react';
import {
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TRoutes } from '../../../utils/helpers';
import {
  ActionsItem,
  ConvertStatusIdToText,
} from '../../../utils/helperComponents';
import { useTranslation } from 'react-i18next';
import {
  DELIVERY_STATUS_BY_ID,
  PAYMENT_ORDER_STATUS_BY_ID,
} from '../../../utils/constants';
import useStyles from './styles';
import TrackTrackNumberComponent from '../../TrackTrackNumberComponent/TrackTrackNumberComponent';
import { IShipmentFromWarehouseTableRowComponent } from './types';
import { getActionOptions } from './options';

const ShipmentFromWarehouseTableRowComponent = ({
  order,
  setOrderToCancel,
  setIsOpenConfirmCancel,
  isWithLabel,
}: IShipmentFromWarehouseTableRowComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openModalForCancelHandler = () => {
    setOrderToCancel(order);
    setIsOpenConfirmCancel(true);
  };

  const getProductBarcodeTitle = (barcode: string) => barcode?.split('.')[0];

  const formattedDate = new Date(
    order?.createdAt as string,
  ).toLocaleDateString();

  const orderIdTitle = order?.barcodeId?.split('.')[0];

  const options = getActionOptions(
    order,
    isWithLabel,
    openModalForCancelHandler,
  );

  return (
    <TableRow>
      <TableCell align='center'>
        <Link
          to={`${
            TRoutes.SHIPMENT_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
          }${order?.id?.toString()}`}
        >
          {orderIdTitle}
        </Link>
        <br />
        {formattedDate}
      </TableCell>
      {isWithLabel ? (
        <TableCell align='center'>
          {order?.receiver?.name}
          <br />
          {order?.receiver?.city},<br />
          {order?.receiver?.country?.name}
        </TableCell>
      ) : null}
      <TableCell align='center'>
        {order?.products?.map((product) => (
          <div>
            <Typography
              className={classes.productTitle}
            >{`${product?.quantity} x ${product?.relatedProduct?.name}`}</Typography>
            <Link
              to={`${
                TRoutes.SETTINGS_MY_PRODUCT_DETAILS_WITHOUT_ID
              }${product?.relatedProduct?.id?.toString()}
              `}
            >
              {`${t('app.id')} ${getProductBarcodeTitle(
                product?.relatedProduct?.barcodeId as string,
              )}`}
            </Link>
            <Typography className={classes.productTitle}>{`${t('app.sku')}: ${
              product?.relatedProduct?.sku
            }`}</Typography>
          </div>
        ))}
      </TableCell>
      <TableCell align='center'>
        {order?.actualAmount
          ? `${order?.actualAmount} ${order?.orderCostCurrency || 'USD'}`
          : null}
      </TableCell>
      <TableCell align='center'>{order.senderMark}</TableCell>
      <TableCell align='center'>
        {order.trackNumber && (
          <TrackTrackNumberComponent
            carrierCode={String(order.carrierCode)}
            trackNumber={order.trackNumber}
          />
        )}
      </TableCell>
      <TableCell align='center'>
        <ConvertStatusIdToText
          type='StatusDelivery'
          parcel={order}
          defaultText={t(
            DELIVERY_STATUS_BY_ID[Number(order.deliveryStatus?.id)],
          )}
        />
      </TableCell>
      <TableCell align='center'>
        <ConvertStatusIdToText
          type='StatusPayment'
          parcel={order}
          defaultText={t(
            PAYMENT_ORDER_STATUS_BY_ID[Number(order.paymentStatus?.id)],
          )}
        />
      </TableCell>
      <TableCell align='center'>
        <Select value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>
          {options?.map((option) => (
            <ActionsItem
              {...option}
              key={option.title}
              itemLinkName={classes.actionMenuItem}
              linkClassName={classes.actionLink}
              iconClassName={classes.actionIcon}
              redColorIconClassName={classes.actionIconCancel}
              greenColorIconClassName={classes.actionIconWatch}
            />
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default ShipmentFromWarehouseTableRowComponent;
