import { History } from 'history';
import { t } from 'i18next';
import {
  CreateWarehouseShipmentOrderInput,
  GetOrderByIdQuery,
} from '../../../../generated/graphql';
import { LOCAL_STORAGE_KEYS } from '../../../../utils/constants';
import { TRoutes } from '../../../../utils/helpers';

type createWarehouseShipmentOrderType = {
  createWarehouseShipmentOrderMutation: any;
  values: CreateWarehouseShipmentOrderInput;
  enqueueSnackbar: any;
  history: History;
  addEtsyTrackNumberMutation: any;
  orderByIdData?: GetOrderByIdQuery;
};

export const createWarehouseShipmentOrder = ({
  createWarehouseShipmentOrderMutation,
  values,
  enqueueSnackbar,
  history,
  addEtsyTrackNumberMutation,
  orderByIdData,
}: createWarehouseShipmentOrderType) => {
  let input = {
    warehouseId: values.warehouseId,
    products: values.products,

    receiver: {
      ...values.receiver,
      id: Number(values.receiver.id),
      //@ts-ignore
      countryId: values.receiver.country.id,
    },
    additionalInsurance: values.additionalInsurance,
    ...(values.additionalInsurance && {
      insuranceAmount: Number(values.insuranceAmount),
    }),
    signature: values.signature,
    typeDeliveryId: values.typeDeliveryId,
    carrierService: values.carrierService,
    lastMileCost: values.lastMileCost,
    shipmentId: values.shipmentId,
    rateId: values.rateId,
    wrap: values.wrap,
    ...(values.message && { message: values.message }),
    ...(values.comment && { comment: values.comment }),
    ...(values.vatKey !== 'EMPTY' && { vatKey: values.vatKey }),
    ...(values.vatValue && { vatValue: values.vatValue }),
    senderMark: values.senderMark,
    orderCostCurrency: values.orderCostCurrency,
    preliminaryCost: values.preliminaryCost,
    handlingFee: values.handlingFee,
    currency: values?.currency?.toUpperCase(),
    marketplaceInvoiceToken: values.marketplaceInvoiceToken,
    ...(orderByIdData && {
      marketPlaceOrderId: orderByIdData?.getOrderById?.id,
    }),
    giftWrap: values.giftWrap,
  };
  //@ts-ignore
  delete input.receiver.country;
  //@ts-ignore
  delete input.receiver.id;
  createWarehouseShipmentOrderMutation({
    variables: {
      data: input,
    },
  })
    .then((response: any) => {
      enqueueSnackbar(
        t('app.shipmentFromWarehouseCreated', {
          value:
            response?.data?.createWarehouseShipmentOrder?.barcodeId?.substring(
              0,
              response?.data?.createWarehouseShipmentOrder?.barcodeId?.length -
                4,
            ),
        }),
        {
          variant: 'success',
        },
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.WAREHOUSE_ID_ORDER,
        response?.data?.createWarehouseShipmentOrder?.sender?.id as string,
      );
      localStorage.setItem(LOCAL_STORAGE_KEYS.TAB_VALUE, String(0));
      history.push(TRoutes.SHIPMENTS_FROM_WAREHOUSE);
    })
    .catch((err: any) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    });
};
