import { TRoutes } from '../utils/helpers';
import FormAddressBookContact from '../viewes/FormAddressBookContact/FormAddressBookContact';
import FormPersonalData from '../viewes/FormPersonalData/FormPersonalData';
import ShipmentFromWarehouseDetails from '../viewes/Fullfilment/ShipmentFromWarehouseDetails/ShipmentFromWarehouseDetails';
import ShipmentFromWarehouseForm from '../viewes/Fullfilment/ShipmentFromWarehouseForm/ShipmentFromWarehouseForm';
import Main from '../viewes/NavigationPages/NavigationMain/Main';
import PaymentSuccessful from '../viewes/PaymentsHistory/PaymentSuccessful';
import PaymentsHistory from '../viewes/PaymentsHistory/PaymentsHistory';
import Reports from '../viewes/Reports/Reports';
import TrackParcelForm from '../viewes/TrackParcelForm/TrackParcelForm';
import WarehouseShipmentList from '../viewes/WarehouseShipment/WarehouseShipmentList/WarehouseShipmentList';
import Contacts from '../viewes/Сontacts/Contacts';
import WarehouseShipmentCreate from '../viewes/WarehouseShipment/WarehouseShipmentForm/WarehouseShipmentForm';
import WarehouseShipmentDetail from '../viewes/WarehouseShipment/WarehouseShipmentDetail/WarehouseShipmentDetail';
import ReturnShipmentsList from '../viewes/Returns/ReturnShipmentsList/ReturnShipmentsList';
import ReturnShipmentDetails from '../viewes/Returns/ReturnShipmentDetail/ReturnShipmentDetail';
import MyPackagesList from '../viewes/Settings/MyPackagesList/MyPackagesList';
import MyProductList from '../viewes/Settings/MyProductList/MyProductList';
import MyProductDetails from '../viewes/Settings/MyProductList/MyProductDetails/MyProductDetails';
import FulfilmentCalculator from '../viewes/FulfilmentCalculator/FulfilmentCalculator';
import LegalInformation from '../viewes/TextPages/LegalInformation/LegalInformation';
import Eula from '../viewes/TextPages/Eula/Eula';
import Requisites from '../viewes/TextPages/Requisites/Requisites';
import MyMarketplaces from '../viewes/Settings/MyMarketplaces/MyMarketplaces';
import Analytics from '../viewes/Analytics/Analytics';
import MarketplacesOrders from '../viewes/MarketplacesOrders/MarketplacesOrders';
import { PersonalData } from '../viewes/UserDataVerify/PersonalData';
import UserDataVerify from '../viewes/UserDataVerify/UserDataVerify';
import ShipmentsTabs from '../viewes/Fullfilment/ShipmentsTabs/ShipmentsTabs';
import SelectTypeShipmentCreate from '../viewes/NavigationPages/NavigationSelectTypeShipmentCreate/SelectTypeShipmentCreate';
import UndeclaredArrivalList from '../viewes/WarehouseShipment/UndeclaredArrivalList/UndeclaredArrivalList';
import UndeclaredArrivalDetails from '../viewes/WarehouseShipment/UndeclaredArrivalDetails/UndeclaredArrivalDetails';

export const routes = [
  { path: TRoutes.MAIN, component: Main },
  { path: TRoutes.CONTACTS, component: Contacts },
  { path: TRoutes.CONTACTS_CREATE, component: FormAddressBookContact },
  { path: TRoutes.CONTACTS_DETAIL, component: FormAddressBookContact },
  { path: TRoutes.PERSONAL, component: FormPersonalData },
  { path: TRoutes.SHIPMENTS_FROM_WAREHOUSE, component: ShipmentsTabs },
  {
    path: TRoutes.SHIPMENT_FROM_WAREHOUSE_DETAILS,
    component: ShipmentFromWarehouseDetails,
  },
  {
    path: TRoutes.SHIPMENT_FROM_WAREHOUSE_SELECT_TYPE_CREATE,
    component: SelectTypeShipmentCreate,
  },
  {
    path: TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITH_LABEL,
    component: ShipmentFromWarehouseForm,
  },
  {
    path: TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITHOUT_LABEL,
    component: ShipmentFromWarehouseForm,
  },
  {
    path: TRoutes.TRACK,
    component: TrackParcelForm,
  },
  {
    path: TRoutes.REPORTS,
    component: Reports,
  },
  {
    path: TRoutes.TRACK_WITH_NUMBER,
    component: TrackParcelForm,
  },
  {
    path: TRoutes.TRACK_WITH_NUMBER_AND_CARRIED_CODE,
    component: TrackParcelForm,
  },
  {
    path: TRoutes.ACCOUNT,
    component: PaymentsHistory,
  },
  {
    path: TRoutes.ACCOUNT_PAYMENT_SUCCESSFUL,
    component: PaymentSuccessful,
  },
  {
    path: TRoutes.ACCOUNT_ID_AMOUNT,
    component: PaymentsHistory,
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_LIST,
    component: WarehouseShipmentList,
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_LIST,
    component: WarehouseShipmentList,
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_CREATE,
    component: WarehouseShipmentCreate,
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_CREATE,
    component: WarehouseShipmentCreate,
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_DETAILS,
    component: WarehouseShipmentDetail,
  },
  {
    path: TRoutes.RETURNS,
    component: ReturnShipmentsList,
  },
  {
    path: TRoutes.RETURN_DETAILS,
    component: ReturnShipmentDetails,
  },
  {
    path: TRoutes.SETTINGS_MY_PACKAGES,
    component: MyPackagesList,
  },
  {
    path: TRoutes.SETTINGS_MY_PRODUCTS,
    component: MyProductList,
  },
  {
    path: TRoutes.SETTINGS_MY_PRODUCT_DETAILS,
    component: MyProductDetails,
  },
  {
    path: TRoutes.FULFILMENT_CALCULATOR,
    component: FulfilmentCalculator,
  },
  {
    path: TRoutes.LEGAL_INFORMATION,
    component: LegalInformation,
  },
  {
    path: TRoutes.EULA,
    component: Eula,
  },
  {
    path: TRoutes.REQUISITES,
    component: Requisites,
  },
  {
    path: TRoutes.MY_SHOPS,
    component: MyMarketplaces,
  },
  {
    path: TRoutes.MY_SHOPS_CREATE,
    component: MyMarketplaces,
  },
  {
    path: TRoutes.MY_SHOPS_EDIT,
    component: MyMarketplaces,
  },
  {
    path: TRoutes.ANALYTICS,
    component: Analytics,
  },
  {
    path: TRoutes.MARKETPLACE_ORDERS,
    component: MarketplacesOrders,
  },
  {
    path: TRoutes.PERSONAl_VERIFY,
    component: PersonalData,
  },
  {
    path: TRoutes.PERSONAl_DATA_VERIFY,
    component: UserDataVerify,
  },
  {
    path: TRoutes.UNDECLARED_ARRIVAL_LIST,
    component: UndeclaredArrivalList,
  },
  {
    path: TRoutes.UNDECLARED_ARRIVAL_DETAILS,
    component: UndeclaredArrivalDetails,
  },
];
