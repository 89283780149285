import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import {
  DEPARTURE_DATE_FILTER,
  LIMIT_ITEM_PAGE,
  TRoutes,
} from '../../../utils/helpers';
import useStyles from './styles';

import FilterSelectLimitItems from '../../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import {
  LOCAL_STORAGE_KEYS,
  DATE_FORMAT,
  COLORS,
  DELIVERY_STATUS_BY_ID,
} from '../../../utils/constants';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import getStatusesList from '../../../GraphQL/queries/getStatusesList';
import { isObject } from 'formik';
import { Edit as EditIcon } from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  GetStatusesListQuery,
  GetStatusesListQueryVariables,
  useGetShipmentBarcodeListLazyQuery,
  useGetWarehouseShipmentsListLazyQuery,
  useRemoveTrackNumberMutation,
  useRemoveWarehouseShipmentMutation,
  Warehouse_Shipment_Type,
  WarehouseShipment,
} from '../../../generated/graphql';
import ModalBox from '../../../components/ModalBox/ModalBox';
import {
  ConvertWarehouseStatus,
  GetCarrierCodeImage,
  renderCarrierCodeValue,
  ShowLoadingText,
} from '../../../utils/helperComponents';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import FormEditTrackNumber from '../../../components/FormEditTrackNumber/FormEditTrackNumber';
import FilterInputSearch from '../../../components/_Fields/FilterInputSearch/FilterInputSearch';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';

const itemsLimit = localStorage.getItem(
  LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
);

const WarehouseShipmentList: React.FC = () => {
  const client = useApolloClient();
  const classes = useStyles();
  const forwardingRoute = useRouteMatch(
    TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_LIST,
  );
  const isForwarding = forwardingRoute?.isExact;
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [warehouseShipmentList, setWarehouseShipmentList] = useState<
    WarehouseShipment[]
  >([]);

  const [isOpenConfirmRemove, setOpenConfirmRemove] = useState(false);
  const [warehouseShipmentToRemove, setWarehouseShipmentToRemove] =
    useState<WarehouseShipment | null>(null);
  const [isLoadingAction, setLoadingAction] = useState(false);

  const [currentChoiceWarehouseShipment, setCurrentChoiceWarehouseShipment] =
    useState<WarehouseShipment | null>(null);
  const [trackNumberToRemove, setTrackNumberToRemove] = useState<any>({});
  const [isOpenFormTrackNumber, setIsOpenEditTrackNumber] = useState(false);
  const [isOpenRemoveTrackNumberModalBox, setIsOpenRemoveTrackNumberModalBox] =
    useState(false);

  const [filter, setFilter] = useState<IFilters>({
    dateFilter: 0,
    deliveryStatusId: 0,
    search: '',
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const statuses = client.readQuery<
    GetStatusesListQuery,
    GetStatusesListQueryVariables
  >({
    query: getStatusesList,
  });

  const [
    getWarehouseShipmentsListLazyQuery,
    { loading: isLoadingGetWarehouseShipmentsList, error, data },
  ] = useGetWarehouseShipmentsListLazyQuery({ fetchPolicy: 'network-only' });

  const [removeWarehouseShipmentMutation] =
    useRemoveWarehouseShipmentMutation();

  const [getBarcodeListQuery] = useGetShipmentBarcodeListLazyQuery();

  const [removeTrackNumber] = useRemoveTrackNumberMutation();

  useEffect(() => {
    data?.getWarehouseShipmentsList?.rows &&
      setWarehouseShipmentList(
        data?.getWarehouseShipmentsList?.rows as WarehouseShipment[],
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isForwarding]);

  useEffect(() => {
    refetchWarehouseShipmentsList();
    // eslint-disable-next-line
  }, [filter, isForwarding]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  const refetchWarehouseShipmentsList = useDebouncedCallback(() => {
    setLoadingAction(true);
    getWarehouseShipmentsListLazyQuery({
      variables: {
        filter: {
          offset: (filter.page - 1) * filter.itemsLimit!,
          limit: filter.itemsLimit,
          dateFilter:
            typeof filter.dateFilter === 'string'
              ? DEPARTURE_DATE_FILTER.ALL_TIME
              : filter.dateFilter,
          deliveryStatusId:
            typeof filter.deliveryStatusId === 'string'
              ? 0
              : filter.deliveryStatusId,
          type: isForwarding
            ? Warehouse_Shipment_Type.Forwarding
            : Warehouse_Shipment_Type.Inbound,
        },
        search: filter.search,
      },
    });
    setLoadingAction(false);
  }, 400);

  const handleRemoveWarehouseShipment = (id: any) => {
    setLoadingAction(true);
    removeWarehouseShipmentMutation({
      variables: { id },
    })
      .then((response) => {
        enqueueSnackbar(t('app.orderDeleted2', { value: id }), {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      })
      .finally(() => {
        setLoadingAction(false);
        setOpenConfirmRemove(false);
        setWarehouseShipmentToRemove(null);
        refetchWarehouseShipmentsList();
      });
  };

  const handleRemoveTrackNumber = (id: any) => {
    removeTrackNumber({
      variables: { shipmentId: id },
    })
      .then(() => {
        enqueueSnackbar(t('app.trackingNumberDeleted'), { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoadingAction(false);
      })
      .finally(() => {
        setIsOpenRemoveTrackNumberModalBox(false);
        refetchWarehouseShipmentsList();
      });
  };

  const handleGetBarcodeList = async (id: any) => {
    const data = await getBarcodeListQuery({ variables: { id } });

    window.open(
      `${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${data?.data?.getShipmentBarcodeList}`,
    );
  };

  return (
    <>
      <Typography variant='h2' align='center'>
        {!isForwarding
          ? t('app.productDelivery').toUpperCase()
          : t('app.forwardingDispatch').toUpperCase()}
      </Typography>

      <div className={classes.filters}>
        <FormControl className={classes.filtersItemSelect}>
          <Select
            displayEmpty
            disableUnderline
            value={filter.dateFilter}
            onChange={(e) => {
              setFilter((state) => ({
                ...state,
                dateFilter: Number(e.target.value),
              }));
            }}
          >
            <MenuItem value='' disabled>
              {t('app.period')}
            </MenuItem>
            <MenuItem value={DEPARTURE_DATE_FILTER.ALL_TIME}>
              {t('app.allTime')}
            </MenuItem>
            <MenuItem value={DEPARTURE_DATE_FILTER.LAST_7}>
              {t('app.lastNDays', { value: 7 })}
            </MenuItem>
            <MenuItem value={DEPARTURE_DATE_FILTER.LAST_30}>
              {t('app.lastNDays', { value: 30 })}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.filtersItemSelect}>
          <Select
            displayEmpty
            disableUnderline
            value={filter.deliveryStatusId}
            onChange={(e) => {
              setFilter((state) => ({
                ...state,
                deliveryStatusId: Number(e.target.value),
                page: 1,
              }));
            }}
          >
            <MenuItem value='' disabled>
              {t('app.deliveryStatus')}
            </MenuItem>
            <MenuItem value={0}>{t('app.allDeliveryStatuses')}</MenuItem>
            {statuses?.getStatusesList?.DeliveryStatusList?.map((status) => {
              return status && status.id ? (
                <MenuItem key={status.id} value={status.id}>
                  {t(DELIVERY_STATUS_BY_ID[Number(status.id)])}
                </MenuItem>
              ) : null;
            })}
          </Select>
        </FormControl>

        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />

        <div className={classes.buttonsForSelected}>
          <Button
            variant='contained'
            onClick={() =>
              history.push(
                !isForwarding
                  ? TRoutes.WAREHOUSE_SHIPMENT_CREATE
                  : TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_CREATE,
              )
            }
          >
            <AddIcon /> {t('app.create')}
          </Button>
        </div>
        <FilterInputSearch setFilter={setFilter} />
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' rowSpan={2}>
                {t('app.shipmentNo')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.date')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.destination')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.trackingNumber')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.deliveryStatus')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.warehouseStatus')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.places')}
              </TableCell>
              <TableCell align='center' colSpan={2}>
                {t('app.expectedQuantity')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.action')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' colSpan={2}>
                {t('app.receivedQuantity')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!data ||
              isLoadingGetWarehouseShipmentsList ||
              isLoadingAction) && (
              <TableRow>
                <TableCell colSpan={15}>
                  <ShowLoadingText />
                </TableCell>
              </TableRow>
            )}
            {!isLoadingGetWarehouseShipmentsList &&
            !isLoadingAction &&
            warehouseShipmentList?.length
              ? warehouseShipmentList?.map(
                  (item) =>
                    item && (
                      <>
                        <TableRow>
                          <TableCell align='center'>
                            <Link
                              to={`${TRoutes.WAREHOUSE}/shipment/details/${item.id}`}
                            >
                              {item?.barcode?.split('.')[0]}
                            </Link>
                          </TableCell>
                          <TableCell align='center'>
                            {moment(Number(item?.createdAt)).format(
                              DATE_FORMAT,
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            {item?.warehouse?.code}
                          </TableCell>
                          <TableCell align='center'>
                            <BoxCentered>
                              {item.trackNumber && item.carrierCode && (
                                <>
                                  <GetCarrierCodeImage
                                    carrierCode={item.carrierCode}
                                  />
                                  <Tooltip
                                    title={`${t(
                                      'app.trackShipment',
                                    )} ${item.carrierCode.toUpperCase()} ${
                                      item.trackNumber
                                    }`}
                                  >
                                    <NavLink
                                      className={classes.btnIcon}
                                      to={`${TRoutes.TRACK}/${item.carrierCode}/${item.trackNumber}`}
                                    >
                                      <span>{item.trackNumber}</span>
                                    </NavLink>
                                  </Tooltip>
                                </>
                              )}
                              {
                                <Tooltip
                                  title={`${t(
                                    'app.changeOrderTrackingNumber',
                                  )} №${item.id}`}
                                >
                                  <IconButton
                                    size='small'
                                    className={classes.btnIcon}
                                    onClick={() => {
                                      setCurrentChoiceWarehouseShipment(item);
                                      setIsOpenEditTrackNumber(true);
                                    }}
                                  >
                                    <EditIcon className={classes.linkIcon} />
                                  </IconButton>
                                </Tooltip>
                              }
                              {item.trackNumber && item.carrierCode && (
                                <Tooltip
                                  title={`${t(
                                    'app.deleteOrderTrackingNumber',
                                  )}${item.id}`}
                                >
                                  <IconButton
                                    size='small'
                                    onClick={() => {
                                      setTrackNumberToRemove({
                                        warehouseShipmentId: item.id,
                                        trackNumber: item.trackNumber,
                                        carrierCode: item.carrierCode,
                                      });
                                      setIsOpenRemoveTrackNumberModalBox(true);
                                    }}
                                  >
                                    <DeleteForeverIcon
                                      style={{ color: COLORS.RED }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </BoxCentered>
                          </TableCell>
                          <TableCell align='center'>
                            {t(
                              DELIVERY_STATUS_BY_ID[
                                Number(item?.deliveryStatus?.id)
                              ],
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            <ConvertWarehouseStatus
                              status={item.warehouseStatus as string}
                            />
                          </TableCell>
                          <TableCell align='center'>{item.places}</TableCell>
                          <TableCell align='center' colSpan={2}>
                            {item.expectedQuantity} / {item.acceptedQuantity}
                          </TableCell>

                          <TableCell align='center'>
                            <Select value={t('app.action')}>
                              <MenuItem
                                value={t('app.action')}
                                disabled
                                style={{ display: 'none' }}
                              >
                                {t('app.action')}
                              </MenuItem>
                              <MenuItem
                                disableGutters
                                divider
                                className={classes.actionMenuItem}
                              >
                                <Tooltip title={t('app.clickToOpenBarcode')}>
                                  <a
                                    href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${item?.barcode}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={classes.actionLink}
                                  >
                                    <img
                                      src={iconBarcode}
                                      alt='barcode'
                                      className={`${classes.actionIcon}`}
                                      style={{ width: 20, height: 20 }}
                                    />
                                    {t('app.barcode')}
                                  </a>
                                </Tooltip>
                              </MenuItem>
                              {!isForwarding ? (
                                <MenuItem
                                  disableGutters
                                  divider
                                  className={classes.actionMenuItem}
                                >
                                  <Tooltip title={t('app.clickToOpenBarcode')}>
                                    <Link
                                      className={classes.actionLink}
                                      to={'#'}
                                      onClick={() => {
                                        handleGetBarcodeList(item.id);
                                      }}
                                    >
                                      <img
                                        src={iconBarcode}
                                        alt='barcode'
                                        className={`${classes.actionIcon}`}
                                        style={{ width: 20, height: 20 }}
                                      />
                                      {t('app.goodsBarcode')}
                                    </Link>
                                  </Tooltip>
                                </MenuItem>
                              ) : null}

                              <MenuItem
                                disableGutters
                                divider
                                className={classes.actionMenuItem}
                              >
                                <Link
                                  className={classes.actionLink}
                                  to={`${TRoutes.WAREHOUSE}/shipment/details/${item.id}`}
                                >
                                  <SearchIcon
                                    className={`${classes.actionIcon} ${classes.actionIconWatch}`}
                                  />
                                  {t('app.view')}
                                </Link>
                              </MenuItem>
                              <MenuItem
                                disableGutters
                                divider
                                className={classes.actionMenuItem}
                                disabled={!!item.acceptedQuantity}
                              >
                                <Link
                                  className={classes.actionLink}
                                  to='#'
                                  onClick={() => {
                                    setOpenConfirmRemove(true);
                                    setWarehouseShipmentToRemove(item);
                                  }}
                                >
                                  <CancelIcon
                                    className={`${classes.actionIcon} ${classes.actionIconCancel}`}
                                  />
                                  {t('app.delete')}
                                </Link>
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      </>
                    ),
                )
              : !!data &&
                !isLoadingGetWarehouseShipmentsList &&
                !isLoadingAction && (
                  <TableRow>
                    <TableCell colSpan={12} align='center'>
                      {t('app.noData2')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        count={data?.getWarehouseShipmentsList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />
      <ModalBox
        isOpen={isOpenConfirmRemove && isObject(warehouseShipmentToRemove)}
        setOpen={setOpenConfirmRemove}
      >
        <Typography variant='h6'>{t('app.confirmDeleteOrder')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setOpenConfirmRemove(false);
              setWarehouseShipmentToRemove(null);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          {statuses && statuses.getStatusesList && (
            <Button
              variant='contained'
              onClick={() => {
                if (isObject(warehouseShipmentToRemove)) {
                  handleRemoveWarehouseShipment(warehouseShipmentToRemove.id);
                }
              }}
              disabled={isLoadingAction}
            >
              {t('app.delete')}
            </Button>
          )}
        </div>
      </ModalBox>

      <FormEditTrackNumber
        setWarehouseShipmentList={setWarehouseShipmentList}
        isOpenEditTrackNumber={isOpenFormTrackNumber}
        setIsOpenEditTrackNumber={setIsOpenEditTrackNumber}
        currentChoiceWarehouseShipment={currentChoiceWarehouseShipment}
        refetchWarehouseShipmentsList={refetchWarehouseShipmentsList}
      />

      <ModalBox
        isOpen={isOpenRemoveTrackNumberModalBox && !!trackNumberToRemove}
        setOpen={setIsOpenRemoveTrackNumberModalBox}
      >
        <Typography variant='h6'>
          {t('app.confirmDeleteTrackingNumber')}
        </Typography>
        {trackNumberToRemove &&
          trackNumberToRemove.warehouseShipmentId &&
          trackNumberToRemove.trackNumber &&
          trackNumberToRemove.carrierCode && (
            <div>
              <br />
              <div>
                {t('app.orderNo')}:&nbsp;
                <a
                  href={`/warehouse/shipment/details/${trackNumberToRemove.warehouseShipmentId}`}
                  // eslint-disable-next-line
                  target='_blank'
                  rel='noreferrer'
                >
                  {`PP-${trackNumberToRemove.warehouseShipmentId}`}
                </a>
              </div>
              <div>
                {t('app.trackingNumber')}:&nbsp;
                <a
                  href={`track/${trackNumberToRemove.carrierCode}/${trackNumberToRemove.trackNumber}`}
                  // eslint-disable-next-line
                  target='_blank'
                  rel='noreferrer'
                >
                  {trackNumberToRemove.trackNumber}
                </a>
              </div>
              {renderCarrierCodeValue(trackNumberToRemove.carrierCode)}
            </div>
          )}
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setIsOpenRemoveTrackNumberModalBox(false);
              setTrackNumberToRemove(undefined);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleRemoveTrackNumber(trackNumberToRemove.warehouseShipmentId);
            }}
            disabled={isLoadingAction}
          >
            <DeleteForeverIcon style={{ color: 'red' }} /> {t('app.delete')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
};

export default WarehouseShipmentList;
