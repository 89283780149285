import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const LegalInformation: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Box m={'15px 0'}>
        <Typography variant='h2' align='center'>
          ЮРИДИЧЕСКАЯ ИНФОРМАЦИЯ
        </Typography>
      </Box>
      <Divider />

      <Box m={'15px 0'}>
        <a
          href={
            'https://lifjot.com/wp-content/uploads/2023/04/polzovatelskoe-soglashenie.pdf'
          }
        >
          {t('app.termOfUse')}
        </a>
      </Box>
    </div>
  );
};

export default LegalInformation;
